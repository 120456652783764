/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StrikeResponse } from '../models/StrikeResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class WebService {

    /**
     * Получение начальной точки диалога
     * @param botId
     * @returns StrikeResponse
     * @throws ApiError
     */
    public static strikeControllerStart(
        botId: string,
    ): CancelablePromise<StrikeResponse> {
        return __request({
            method: 'GET',
            path: `/api/web/v1/${botId}/start`,
        });
    }

    /**
     * Получение определённой точки диалога
     * @param id
     * @param botId
     * @returns StrikeResponse
     * @throws ApiError
     */
    public static strikeControllerStep(
        id: string,
        botId: string,
    ): CancelablePromise<StrikeResponse> {
        return __request({
            method: 'GET',
            path: `/api/web/v1/${botId}/step/${id}`,
        });
    }

}