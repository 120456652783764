import React, { createContext, useState } from "react"
import { isFunction, isObject } from "@utils"

export type UiState = {
    isBotOpen: boolean
    setUIStateValue: SetStateValueFn
}

type SSVCallback = (state: UiState) => UiState
export type SetStateValueFn = (
    key: (keyof UiState & string) | Partial<UiState> | SSVCallback,
    value?: any
) => void

const initialState: UiState = {
    isBotOpen: false,
    setUIStateValue: () => null,
}

export const UiContext = createContext<UiState>(initialState)

const UiProvider: React.FC = ({ children }) => {
    const [state, setState] = useState({ ...initialState })

    const uiState: UiState = {
        ...state,
        setUIStateValue: (key, value) => {
            setState(state => {
                if (isFunction(key)) {
                    return (key as Function)(state)
                } else if (typeof value !== "undefined") {
                    return { ...state, [`${key}`]: value }
                } else if (isObject(key)) {
                    return { ...state, ...(key as Record<keyof UiState, any>) }
                }
                return state
            })
        },
    }
    return <UiContext.Provider value={uiState}>{children}</UiContext.Provider>
}

export default UiProvider
