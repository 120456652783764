import "typeface-alegreya-sans"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

import React from "react"
import { CookiesProvider } from "react-cookie"

import BotProvider from "./src/context/Bot"
import UIProvider from "./src/context/UI"

export const wrapRootElement = ({ element }) => {
    return (
        <CookiesProvider>
            <UIProvider>
                <BotProvider>{element}</BotProvider>
            </UIProvider>
        </CookiesProvider>
    )
}
