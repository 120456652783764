import useQueueState from "@hooks/useQueueState"
import React, { useEffect, createContext } from "react"

// Local data for debugging/testing
// import steps from "src/common/steps.json"

import {
    OpenAPI,
    WebService,
    StrikeResponse,
    CancelablePromise,
} from "src/services/openapi"

type Context = {
    log: StrikeResponse[]
    start: () => CancelablePromise<void> | {}
    step: (stepId: string) => CancelablePromise<void> | {}
}

type Props = {
    children: TODO_ANY
}

export const BotContext = createContext<Context>({
    log: [],
    start: async () => {},
    step: async (stepId: string) => {},
})

OpenAPI.BASE = process.env.GATSBY_API_URL as string
const BOT_ID = process.env.GATSBY_BOT_ID as string

const LOG_LENGTH = 5

const BotProvider = ({ children }: Props) => {
    const [log, controls] = useQueueState<StrikeResponse>([])
    const { enqueue, dequeue } = controls

    useEffect(() => {
        if (log.length > LOG_LENGTH) {
            dequeue()
        }
    }, [log])

    const start = async (): Promise<void> => {
        try {
            const res = await WebService.strikeControllerStart(BOT_ID)
            if (res) enqueue(res)
        } catch (error) {
            console.error(error)
        }
    }

    const step = async (stepId: string): Promise<void> => {
        try {
            const res = await WebService.strikeControllerStep(stepId, BOT_ID)
            if (res) enqueue(res)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <BotContext.Provider value={{ log, start, step }}>
            {children}
        </BotContext.Provider>
    )
}

export default BotProvider
