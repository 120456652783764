/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * Получить данные бота
     * @returns any Содержимое бота в формате json
     * @throws ApiError
     */
    public static botControllerGetContent(): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/admin/v1/bot`,
        });
    }

    /**
     * Сохранить данные бота
     * @returns any
     * @throws ApiError
     */
    public static botControllerSaveContent(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/api/admin/v1/bot`,
        });
    }

}