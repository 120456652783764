const linkify = require("linkify-it")()

export const parseMsg = (msg?: string) => {
    if (!msg) return ""

    let result = `${msg}`

    const links = linkify.match(msg)
    if (links) {
        links.forEach((l: { text: string }) => {
            result = result.replace(
                l.text,
                `<a href="${l.text}" target="_blank" rel="noreferrer noopener">${l.text}</a>`
            )
        })
    }

    return result.replace(new RegExp("\n", "gm"), "<br />")
}

export const smoothScrollToEl = (id: string) => {
    const el = document.getElementById(id)
    if (!el) return
    const offset = el.offsetTop - 80
    window.scroll({
        top: offset,
        left: 0,
        behavior: "smooth",
    })
}

export const isFunction = (functionToCheck: any): boolean => {
    return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === "[object Function]"
    )
}

export const isObject = (obj: any): boolean => {
    return obj && Object.prototype.toString.call(obj) === "[object Object]"
}
